<!-- Компонент выбора бизнес-ланча --->
<template>
	<div class="business-lunch-list">

		<!-- Слайдер-контейнер -->
		<swiper
			:options="swiperParams"
			ref="swiper"
			@slideChange="checkCurrentSlide"
		>

			<swiper-slide
				v-for="item in items"
				:key="item.type"
				class="business-lunch-list__item"
				:style="{backgroundImage: `url(${getImg('secondAndSalad-bg.webp')})`}"
			>

				<!-- Верхняя часть карточки (фото, название, описание) -->
				<div
					class="business-lunch-list__item-wrap"
					@click="goToConstructor(item)"
				>
					<img
						class="business-lunch-list__img"
						:src="item.preview_picture"
					/>
					<div class="business-lunch-list__collect-badge">{{ badge }}</div>
					<div class="business-lunch-list__content">

						<div class="business-lunch-list__name">{{ item.name }}</div>

						<div class="business-lunch-list__description">
							{{ description }}
						</div>

						<!-- Нижняя часть карточки (цена, бонусы, кнопка) -->
						<div class="business-lunch-list__price">
							<div class="business-lunch-list__rub">{{ item.price }} ₽</div>
							<div
								class="business-lunch-list__bonus"
								v-if="item.balls && currentBasket?.owner"
							>
								<span class="business-lunch-list__bonus-num">+{{ item.balls }}</span>
								<img src="@/assets/img/icons/bonus.svg"/>
							</div>
							<button
								class="business-lunch-list__collect"
							>Собрать
							</button>
						</div>

					</div>
				</div>
			</swiper-slide>
			<div
				class="swiper-pagination swiper-pagination-bullets"
				slot="pagination"
			></div>
		</swiper>

		<!-- Кнопка "Влево" -->
		<button
			class="business-lunch-list__arrow-left"
			@click="onPrev"
			v-if="showLeftArrow"
		>
			<svg
				width="11"
				height="18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="m9.5 1-7 8 7 7.75"
					stroke="#3D4248"
					stroke-width="3"
				/>
			</svg>
		</button>

		<!-- Кнопка "Вправо" -->
		<button
			class="business-lunch-list__arrow-right"
			@click="onNext"
			v-if="showRightArrow"
		>
			<svg
				width="11"
				height="18"
				viewBox="0 0 11 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.5 1L8.5 9L1.5 16.75"
					stroke="#3D4248"
					stroke-width="3"
				/>
			</svg>
		</button>

	</div>
</template>

<script>
import 'swiper/css/swiper.min.css'
import {useYandexMetrika} from "@/composables";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {mapGetters} from "vuex";
import {notification} from "@/utils";

export default {
	setup()
	{
		const {yandexMetrikaGoal} = useYandexMetrika();

		return {yandexMetrikaGoal};
	},
	name: 'BusinessMealList',
	components: {Swiper, SwiperSlide},
	props: {

		// Типы бизнес-ланчей. Формат:
		// [{
		//   type: 'LUNCH_TYPE',
		//   name: 'Название ланча',
		//   img: 'Изображение ланча',
		//   bg: 'Изображение для фона карточки',
		//   cost: Стоимость в рублях,
		//   bonus: Количество баллов
		// }, ...]

		items: Array,
		badge: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
	},

	data: () => ({
		// Если true, изображения будут браться из ассетов
		devMode: true,

		// Опции слайдера
		swiperParams: {
			slidesPerView: 'auto',
			spaceBetween: 16,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				renderBullet(index, className)
				{
					return `<span class="${className}"></span>`;
				},
			},
		},

		showLeftArrow: false,
		showRightArrow: true
	}),
	computed: {
		...mapGetters({
			isDone: "getDone",
			currentBasket: "getCurrentBasket",
		})
	},
	methods: {
		getImg(url)
		{
			return this.devMode ?
				require(`@/assets/img/Dev/lunches/${url}`)
				: url;
		},

		/** Предыдущий слайд */
		onPrev()
		{
			if (!this.$refs.swiper.swiperInstance.slidePrev())
				this.$refs.swiper.swiperInstance.slideTo(this.$refs.swiper.swiperInstance.slides.length);
		},

		/** Следующий слайд */
		onNext()
		{
			this.$refs.swiper.swiperInstance.slideNext();
			if (this.$refs.swiper.swiperInstance.isEnd)
				this.showRightArrow = false;
			else
				this.showRightArrow = true;
		},

		checkCurrentSlide()
		{
			if (this.$refs.swiper.swiperInstance.activeIndex == 0)
				this.showLeftArrow = false;
			else
				this.showLeftArrow = true;

			if (this.$refs.swiper.swiperInstance.isEnd)
				this.showRightArrow = false;
			else
				this.showRightArrow = true;
		},

		goToConstructor(item)
		{
			if (this.isDone)
			{
				const title = 'Вы завершили сборку коллективной корзины';
				return notification({title});
			}

			this.yandexMetrikaGoal(45209871, 'main_dish');
			this.$emit('setCurrentBusinessMealType');
			this.$store.commit('choseBusinessMeal', item);
			this.$router.push('/constructor/');
		},
	},
}
</script>

<style lang="scss">
.business-lunch-list__item-wrap
{cursor: pointer;}

.business-lunch-list
{
	position: relative;

	.swiper-container
	{
		padding-top: 38px;
	}

	&__item.swiper-slide
	{
		max-height: 312px;
		height: 312px;
		width: 262px;
		max-width: 262px;
		background-size: cover;
		border-radius: 24px;
		position: relative;
	}

	.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction
	{bottom: 0;}

	.swiper-wrapper
	{padding-bottom: 25px;}

	.swiper-pagination-bullet
	{
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background: #e5e5e7;
		margin-right: 8px;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
		cursor: pointer;
		opacity: 1;
		border: none;
	}

	.swiper-pagination-bullet-active
	{
		background: #40ae49;
		border-color: #40ae49;
	}

	&__img
	{
		margin-top: -38px;
		max-height: 217px;
		width: 100%;
		object-fit: contain;
		cursor: pointer;
	}

	&__collect-badge
	{
		position: absolute;
		width: auto;
		left: 24px;
		font-size: 12px;
		top: 136px;
		padding: 0 7px;
		background: #FFEF64;
		border-radius: 6px;
		letter-spacing: .01px;
	}

	&__content
	{
		margin-top: -18px;
		padding: 0 0 24px 24px;
	}

	&__name
	{
		font-size: 20px;
		min-height: 36px;
		font-family: $mainFontBoldNew;
	}

	&__description
	{
		font-size: 12px;
		line-height: 16px;
		color: $greyLightText;
		font-weight: normal;
		margin-bottom: 12px;
		letter-spacing: .01px;
		max-width: 90%;
	}

	&__price
	{
		position: absolute;
		bottom: 24px;
		display: flex;
		align-items: center;
	}

	&__rub
	{
		font-size: 24px;
		font-family: $mainFontBoldNew;
		margin-right: 5px;
	}

	&__bonus
	{
		background: $green;
		color: #fff;
		font-size: 14px;
		border-radius: 4px;
		width: 52px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;

		img
		{
			margin-left: 3px;
			height: 14px;
			width: 13px;
		}
	}

	&__collect
	{
		width: 89px;
		height: 40px;
		background: $red;
		color: #fff;
		border: none;
		font-size: 14px;
		font-family: $mainFontBoldNew;
		transition: background-color .3s ease;
		border-radius: 8px;

		&:hover
		{
			background: $redHover;
		}
	}

	&__arrow-left, &__arrow-right
	{
		border: none;
		bottom: 147px;
		position: absolute;
		width: 60px;
		height: 60px;
		background: #fff;
		box-shadow: 0px 10px 40px rgba(123, 123, 123, 0.15);
		z-index: 1;
		border-radius: 100%;
	}

	.swiper-pagination-bullets
	{display: none;}

	&__arrow-left
	{
		left: -30px;
	}

	&__arrow-right
	{
		right: -30px;
	}

	@media (max-width: 1439px)
	{

		margin-left: -44px;
		// margin-right: -44px;

		.swiper-container
		{
			padding-left: 44px;
		}

		&__arrow-left
		{
			left: 14px;
		}

		// &__arrow-right {
		//   display: none;
		// }

	}

	@media (max-width: 567px)
	{
		margin-left: -32px;
		margin-right: -32px;

		.swiper-container
		{
			padding-left: 32px;
			padding-right: 32px;
		}

		&__arrow-left
		{
			display: none;
		}
		&__arrow-right
		{
			display: none;
			right: 10px
		}
		.swiper-pagination-bullets
		{display: block;}
		.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction
		{bottom: 0;}
		.swiper-wrapper
		{padding-bottom: 29px;}
	}

}

</style>
